import flex from "@reflexjs/preset-flex"

export default {
  preset: flex,
  colors: {
    muted: "antiquewhite",
    modes: {
      dark: {
        muted: "#0a151b",
      }
    }
  },
}